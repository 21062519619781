<template>
    <div class="flex min-h-[100vh] flex-col">
        <SiteNotificationContainer />
        <DesignLabHeaderWhite
            :has-unsaved-changes="route?.path == '/roastar-design-lab/review'"
        />

        <main class="flex-1">
            <slot />
        </main>

        <Footer />
    </div>
</template>

<script setup type="ts">
import Footer from '~/layouts/includes/Footer.vue';
import SiteNotificationContainer from '~/layouts/includes/SiteNotificationContainer.vue';
import DesignLabHeaderWhite from '~/components/design-lab/components/DesignLabHeaderWhite.vue';

const route = useRoute();

useServerHead({
    link: [
        {
            href: "/images/favicon.svg",
            rel: "icon",
            type: "image/svg+xml",
        },
    ],
});

useHead({
    meta: [
        {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
        }
    ]
});
</script>
