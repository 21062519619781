<template>
    <TopBar />
    <div class="sticky top-0 z-[99998] flex-1 overflow-hidden bg-white shadow-lg lg:h-[100px] lg:flex-none lg:overflow-visible">
        <nav id="navbar" class="container flex flex-row items-center mx-auto h-full justify-between gap-x-12">
            <div
                class="container mx-auto flex flex-row items-center  gap-4"
            >
                <a href="/" title="Roastar">
                    <img
                        src="~/assets/images/roastar_logo.svg"
                        class="h-8 hidden lg:inline"
                        width="163"
                        height="32"
                        alt="Roastar Logo"
                    />
                    <img
                        src="~/assets/images/roastar_star_red.svg"
                        alt="Roastar Logo"
                        class="w-12 lg:hidden py-2"
                    />
                </a>
                <button
                    :disabled="route.name === 'design-lab'"
                    class="items-center font-semibold no-underline text-xl sm:text-[24px]"
                    @click.prevent="onLeave('/design-lab')"
                >
                    Roastar Design Lab
                </button>
            </div>
        </nav>

        <Modal ref="leaveConfirmationModal">
            <template #title>You are about to leave the Design Lab.</template>

            <p>
                You are about to exit the Design Lab and lose the progress
                you’ve made. Are you sure you want to leave?
            </p>

            <template #actions="{ close }">
                <button class="btn-secondary" @click="close">
                    No, finish my design
                </button>

                <NuxtLink class="btn-primary" :to="leaveTo" @click="close">
                    Yes, exit the Design Lab
                </NuxtLink>
            </template>
        </Modal>
    </div>
</template>

<script setup lang="ts">
import Modal from '~/components/Modal.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const leaveConfirmationModal = ref<InstanceType<typeof Modal> | null>(null);
const leaveTo = ref();

const props = withDefaults(
    defineProps<{
        hasUnsavedChanges: boolean;
    }>(),
    {
        hasUnsavedChanges: false,
    },
);

const onLeave = (redirectTo: string) => {
    leaveTo.value = redirectTo;
    if (props.hasUnsavedChanges) {
        leaveConfirmationModal.value?.open();
    } else {
        navigateTo(leaveTo.value);
    }
};
</script>
